import React from "react";

import { Link } from "react-router-dom";

// material-ui
import { ButtonBase, Grid, Typography } from "@mui/material";

// project imports
import config from "config";
import Logo from "ui-component/Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={6} justifyContent="space-between" alignItems="end">
                <Logo />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h5">ShipLocal</Typography>
            </Grid>
        </Grid>
    </ButtonBase>
);

export default LogoSection;
