import React, { useState } from "react";
import {
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    Stack,
    Typography,
    IconButton,
    TextField
} from "@mui/material";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import { cancelOrder } from "views/pages/orders/orders.action";
import { useDispatch } from "react-redux";

export default function OrderCancelDialog({ isOpen = false, onClose, orderId, orderDetails }) {
    const dispatch = useDispatch();
    const [cancellationReason, setCancellationReason] = useState("");

    const { vendor: vendorName, status: orderStatus } = orderDetails;

    const handleCancellationReasonChange = (e) => {
        setCancellationReason(e.target.value);
    };

    const handleOrderCancel = () => {
        onClose();
        const payload = {
            cancellationReason: cancellationReason,
            orderId: orderId
        };
        cancelOrder(dispatch, payload);
    };

    return (
        <div>
            <IconButton>
                <CancelIcon color="primary" />
            </IconButton>
            <Dialog open={isOpen} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to cancel this order?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>
                    <Stack>
                        <Typography>Order ID: {orderId}</Typography>
                        <Typography>Vendor: {vendorName}</Typography>
                        <Typography>Current Status: {orderStatus}</Typography>
                    </Stack>
                    <TextField
                        margin="dense"
                        id="cancellation-reason"
                        label="Cancellation Reason"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={cancellationReason}
                        onChange={handleCancellationReasonChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>No</Button>
                    <Button onClick={handleOrderCancel} disabled={!cancellationReason}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

OrderCancelDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    orderId: PropTypes.string.isRequired,
    orderDetails: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
