export const initialState = {
    isLoading: false,
    ordersList: undefined,
    err: null
};

export const ACTION_TYPES = {
    FETCH_ORDERS_LIST_BEGIN: "FETCH_ORDERS_LIST_BEGIN",
    FETCH_ORDERS_LIST_SUCCESS: "FETCH_ORDERS_LIST_SUCCESS",
    FETCH_ORDERS_LIST_FAILURE: "FETCH_ORDERS_LIST_FAILURE",

    CHANGE_ORDER_VENDOR_BEGIN: "CHANGE_ORDER_VENDOR_BEGIN",
    CHANGE_ORDER_VENDOR_SUCCESS: "CHANGE_ORDER_VENDOR_SUCCESS",
    CHANGE_ORDER_VENDOR_FAILURE: "CHANGE_ORDER_VENDOR_FAILURE",

    CANCEL_ORDER_BEGIN: "CANCEL_ORDER_BEGIN",
    CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",

    FETCH_ORDERS_STATUSES_BEGIN: "FETCH_ORDERS_STATUSES_BEGIN",
    FETCH_ORDERS_STATUSES_SUCCESS: "FETCH_ORDERS_STATUSES_SUCCESS",
    FETCH_ORDERS_STATUSES_FAILURE: "FETCH_ORDERS_STATUSES_FAILURE"
};
