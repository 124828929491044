import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

export default function LoaderBackdrop() {
    const backdropInfo = useSelector((state) => state.backdrop);
    return (
        <div>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 500 }} open={backdropInfo.isVisible}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
