import { NOTIFICATION_TYPES } from "common/constants/common.enums";

export const initialState = {
    isOpen: false,
    type: NOTIFICATION_TYPES.INFO,
    title: undefined,
    msg: undefined,
    extraInfo: undefined,
    okText: "OK",
    cancelText: "Close",
    callback: undefined
};

export const ACTION_TYPES = {
    OPEN_NOTIFICATION_DIALOG: "OPEN_NOTIFICATION_DIALOG",
    CLOSE_NOTIFICATION_DIALOG: "CLOSE_NOTIFICATION_DIALOG"
};
