import React, { useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import {
    // Grid,
    IconButton,
    Stack,
    Tooltip,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Paper,
    FormControlLabel,
    Switch,
    Typography
    // Checkbox
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ChangeVendorDialog from "../dialog/ChangeVendorDialog.component";
import OrderCancelDialog from "../dialog/OrderCancelDialog.component";
import OrderDetailsDialog from "../dialog/OrderDetailsDialog.component";
import dayjs from "dayjs";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "shipper_name",
        numeric: false,
        disablePadding: true,
        label: "Shipper Name"
    },
    {
        id: "display_order_id",
        numeric: true,
        disablePadding: false,
        label: "Shipper Order ID"
    },
    {
        id: "created_at",
        numeric: true,
        disablePadding: false,
        label: "Created At"
    },
    {
        id: "order_status",
        numeric: true,
        disablePadding: false,
        label: " Order Status"
    },
    {
        id: "last_known_status",
        numeric: true,
        disablePadding: false,
        label: "Ful. Status"
    },
    {
        id: "pickup_google_address",
        numeric: false,
        disablePadding: true,
        label: "Pickup G. Address"
    },
    {
        id: "drop_google_address",
        numeric: false,
        disablePadding: true,
        label: "Drop G. Address"
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: "Actions"
    }
];

function EnhancedTableHead(props) {
    // eslint-disable-next-line no-unused-vars
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/**
        <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts"
                        }}
                    />
                </TableCell>
        */}

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

const CANCEL_DIALOG_INFO_INITIAL_STATE = {
    isOpen: false,
    orderId: undefined,
    orderDetails: null
};

const VENDOR_CHANGE_DIALOG_INFO_INITIAL_STATE = {
    isOpen: false,
    orderId: undefined,
    orderDetails: null
};

export default function OrderListTable({ ordersList }) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [cancelDialogInfo, setCancelDialogInfo] = useState(CANCEL_DIALOG_INFO_INITIAL_STATE);
    const [vendorChangeDialogInfo, setVendorChangeDialogInfo] = useState(VENDOR_CHANGE_DIALOG_INFO_INITIAL_STATE);
    const [orderDetailsDialogInfo, setOrderDetailsDialogInfo] = useState(null);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = ordersList.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    // eslint-disable-next-line no-unused-vars
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ordersList.length) : 0;

    const handleCancelDialogOpen = (orderDetails) => {
        setCancelDialogInfo({
            ...cancelDialogInfo,
            isOpen: true,
            orderId: orderDetails.order_id,
            orderDetails: {
                vendorId: orderDetails.fulfillment_provider_id,
                vendor: orderDetails.fulfillment_provider_name,
                status: orderDetails.order_status
            }
        });
    };
    const handleCancelDialogClose = () => {
        setCancelDialogInfo(CANCEL_DIALOG_INFO_INITIAL_STATE);
    };

    const handleVendorChangeDialogOpen = (orderDetails) => {
        setVendorChangeDialogInfo({
            ...vendorChangeDialogInfo,
            isOpen: true,
            orderId: orderDetails.order_id,
            orderDetails: {
                vendorId: orderDetails.fulfillment_provider_id,
                vendor: orderDetails.fulfillment_provider_name,
                status: orderDetails.order_status
            }
        });
    };

    const handleVendorChangeDialogClose = () => {
        setVendorChangeDialogInfo(VENDOR_CHANGE_DIALOG_INFO_INITIAL_STATE);
    };

    const handleViewCurrentOrderDetails = (orderDetails) => {
        setOrderDetailsDialogInfo(orderDetails);
    };

    const handleCloseCurrentOrderDetails = () => {
        setOrderDetailsDialogInfo(null);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                {/**<EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={ordersList.length}
                        />
                        <TableBody>
                            {stableSort(ordersList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${row.display_order_id}-${row.shipper_name}-${row.created_at}-${index}`}
                                            selected={isItemSelected}
                                        >
                                            {/**

                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby": labelId
                                                    }}
                                                />
                                            </TableCell>{" "}
                                        
                                        */}
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.shipper_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography
                                                    onClick={() => handleViewCurrentOrderDetails(row)}
                                                    onKeyDown={() => handleViewCurrentOrderDetails(row)}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "blue"
                                                    }}
                                                >
                                                    {row.display_order_id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">{dayjs.unix(row.created_at).format("YYYY-MM-DD HH:mm")}</TableCell>
                                            <TableCell align="right">{row.order_status}</TableCell>
                                            <TableCell align="right">{row.last_known_status}</TableCell>
                                            <TableCell align="right">{row.pickup_google_address}</TableCell>
                                            <TableCell align="right">{row.drop_google_address}</TableCell>
                                            <TableCell align="center">
                                                <Stack direction="row" spacing={2}>
                                                    <Tooltip title="Cancel this order">
                                                        <CancelIcon
                                                            style={{ cursor: "pointer" }}
                                                            color="error"
                                                            onClick={() => handleCancelDialogOpen(row)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Change Vendor">
                                                        <ChangeCircleIcon
                                                            color="primary"
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => handleVendorChangeDialogOpen(row)}
                                                        />
                                                    </Tooltip>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={ordersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {cancelDialogInfo.isOpen && cancelDialogInfo.orderId && cancelDialogInfo.orderDetails && (
                    <OrderCancelDialog
                        isOpen={cancelDialogInfo.isOpen}
                        orderId={cancelDialogInfo.orderId}
                        orderDetails={cancelDialogInfo.orderDetails}
                        onClose={handleCancelDialogClose}
                    />
                )}
                {vendorChangeDialogInfo.isOpen && vendorChangeDialogInfo.orderId && vendorChangeDialogInfo.orderDetails && (
                    <ChangeVendorDialog
                        isOpen={vendorChangeDialogInfo.isOpen}
                        orderId={vendorChangeDialogInfo.orderId}
                        orderDetails={vendorChangeDialogInfo.orderDetails}
                        onClose={handleVendorChangeDialogClose}
                    />
                )}
                {orderDetailsDialogInfo && (
                    <OrderDetailsDialog orderDetails={orderDetailsDialogInfo} onClose={handleCloseCurrentOrderDetails} />
                )}
            </Paper>
            <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
        </Box>
    );
}

OrderListTable.propTypes = {
    ordersList: PropTypes.array
};
