import React from "react";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid, Typography, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { closeNotificationDialog } from "./NotifyDialog.action";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { NOTIFICATION_TYPES } from "common/constants/common.enums";

const getNotificatonIcon = (type) => {
    switch (type) {
        case NOTIFICATION_TYPES.ERROR:
            return <ErrorIcon color="error" fontSize="large" />;
        case NOTIFICATION_TYPES.WARNING:
            return <WarningIcon color="warning" fontSize="large" />;
        case NOTIFICATION_TYPES.SUCCESS:
            return <CheckCircleIcon color="success" fontSize="large" />;
        default:
            return <InfoIcon color="info" fontSize="large" />;
    }
};

export default function NotificationDialog() {
    const dispatch = useDispatch();
    const dialogInfo = useSelector((state) => state.notification);

    // extraInfo
    const { isOpen, type, title, msg, okText, cancelText, callback, showCancelButton } = dialogInfo;

    const handleClose = () => {
        closeNotificationDialog(dispatch);
        if (callback && typeof callback === "function") {
            callback();
        }
    };

    const notificationIcon = getNotificatonIcon(type);

    return (
        <div>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={1}>
                            {notificationIcon}
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="h3">{title}</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>

                    <Stack direction="row">
                        <Typography>{msg}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {showCancelButton && <Button onClick={handleClose}>{cancelText}</Button>}
                    <Button onClick={handleClose}>{okText}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
