import { ACTION_TYPES, initialState } from "./NotifyDialog.constant";

export const notificationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES.OPEN_NOTIFICATION_DIALOG:
            return {
                ...state,
                isOpen: true,
                type: action.payload.type,
                msg: action.payload.msg,
                title: action.payload.title,
                extraInfo: action.payload.extraInfo || undefined,
                okText: action.payload.okText || initialState.okText,
                cancelText: action.payload.cancelText || initialState.cancelText,
                callback: action.payload.callback || initialState.callback
            };
        case ACTION_TYPES.CLOSE_NOTIFICATION_DIALOG:
            return {
                ...initialState
            };
        default:
            return state;
    }
};
