// assets
import { IconKey } from "@tabler/icons";
import ListIcon from "@mui/icons-material/List";

// constant
const icons = {
    IconKey,
    ListIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: "pages",
    title: "Pages",
    caption: "Dashboard Pages",
    type: "group",
    children: [
        // {
        //     id: "authentication",
        //     title: "Authentication",
        //     type: "collapse",
        //     icon: icons.IconKey,

        //     children: [
        //         {
        //             id: "login3",
        //             title: "Login",
        //             type: "item",
        //             url: "/pages/login/login3",
        //             target: true
        //         },
        //         {
        //             id: "register3",
        //             title: "Register",
        //             type: "item",
        //             url: "/pages/register/register3",
        //             target: true
        //         }
        //     ]
        // },
        {
            id: "orders",
            title: "Orders",
            type: "collapse",
            icon: icons.ListIcon,

            children: [
                {
                    id: "orders-list",
                    title: "Ongoing",
                    type: "item",
                    url: "/orders/list",
                    target: false,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default pages;
