import { initialState, ACTION_TYPES } from "./auth.constatns";

const authReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES.INITIATE_LOGIN_BEGIN:
            return {
                ...state,
                loading: true
            };
        case ACTION_TYPES.INITIATE_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                token: action.payload
            };
        case ACTION_TYPES.LOGOUT_USER:
            return {
                ...initialState
            };
        case ACTION_TYPES.INITIATE_LOGIN_FAILURE:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default authReducer;
