// import axios from 'axios';
// import { config } from 'common/configs/config';

// export const SHIPLOCALAPI = (method, url, payload) => {
//     return API(method, url, payload, config.gdbxApiHostname, getGdbxApiHeaders('application/json'));
// };

// export const SHIPLOCALAPIFORMAPI = (method, url, payload) => {
//     return FORMAPI(method, url, payload, config.gdbxApiHostname, getGdbxApiHeaders('multipart/form-data'));
// };
// export const CALLTHIRDPARTYAPI = (method, url, payload, contentType) => {
//     return THIIRDPARTYAPI(method, url, payload, contentType);
// };

// var userAgent = window.navigator.userAgent.toLowerCase(),
//     ios = /iphone|ipod|ipad/.test(userAgent);

// const getGdbxApiHeaders = function (contentType) {
//     return {
//         'Content-Type': contentType,
//         'X-GDBX-CLIENTID': window.localStorage.getItem('clientid') || '',
//         'X-GDBX-AUTHTOKEN': window.localStorage.getItem('authtoken') || '',
//         'X-GDBX-USERNAME': window.localStorage.getItem('username') || '',
//         'X-GDBX-VER': config.version,
//         'X-GDBX-PACKAGE': window.ReactNativeWebView ? 'com.tsepak.supplierchat' : config.package,
//         'X-GDBX-CLIENT': window.ReactNativeWebView ? (ios ? 'ios' : 'android') : 'webapp'
//     };
// };

// const API = (method, url, payload, server, headers) => {
//     let options = {
//         headers: headers,
//         method,
//         url: `${server}${url}`
//     };

//     if (method === 'POST' || method === 'PATCH') {
//         options.data = payload;
//     }
//     //
//     // if (window.loginKey && !window.__loginKeyLoginDone) {
//     // } else {
//     //   if (!window.___gdbxClientId) {
//     //     window.___gdbxClientId = headers['X-GDBX-CLIENTID'];
//     //   } else {
//     //     const lsClientId = window.localStorage.getItem('clientid');
//     //     if (lsClientId !== window.___gdbxClientId && !window.secondLoginDetected) {
//     //       window.secondLoginDetected = true;
//     //       alert("Second login detected. Reloading...");
//     //       window.location.reload();
//     //     }
//     //   }
//     // }

//     return new Promise((resolve, reject) => {
//         axios(options)
//             .then((res) => {
//                 return res.data;
//             })
//             .then((respJson) => {
//                 resolve(respJson);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });
// };

// const FORMAPI = (method, url, payload, server, headers) => {
//     let options = {
//         headers: headers,
//         method,
//         url: `${server}${url}`
//     };

//     if (method === 'POST' || method === 'PATCH') {
//         let formData = new FormData();
//         for (let key in payload) {
//             formData.append(key, payload[key]);
//         }
//         options.data = formData;
//     }

//     return new Promise((resolve, reject) => {
//         axios(options)
//             .then((res) => {
//                 return res.data;
//             })
//             .then((respJson) => {
//                 resolve(respJson);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });
// };

// const THIIRDPARTYAPI = (method, url, payload, contentType) => {
//     let options = {
//         headers: {
//             'Content-Type': contentType || 'application/json'
//         },
//         method,
//         url: `${url}`
//     };

//     if (method === 'POST' || method === 'PATCH') {
//         options.data = payload;
//     }
//     return new Promise((resolve, reject) => {
//         axios(options)
//             .then((res) => {
//                 return res.data;
//             })
//             .then((respJson) => {
//                 resolve(respJson);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });
// };

import axios from "axios";

export const triggerApi = (method, url, payload = true, returnRequest) => {
    return API(method, url, payload, returnRequest);
};

// eslint-disable-next-line no-unused-vars
let axiosRequestInterceptor = null;
// eslint-disable-next-line no-unused-vars
let axiosResponseInterceptor = null;
let isRefreshing = false;
let failedQueue = [];

// Processing the failed reuqests inside a the failed queue.
const processQueue = (error, token = null) => {
    // console.log("failedQueue", failedQueue);
    failedQueue.forEach((prom) => {
        // console.log("prom", prom);
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// Main API method
const API = (method, url, payload, returnRequest) => {
    const base_url_s = process.env.REACT_APP_BASE_URL;
    console.log("base url is ", base_url_s);
    // console.log("failedQueueVal", failedQueue);

    if (!url.startsWith("https")) {
        // Using request interceptor to append header properties before calling the API
        axiosRequestInterceptor = axios.interceptors.request.use(function (config) {
            config["headers"] = {};
            config.headers["user-from"] = "I-CONSOLE";

            // console.log("config.url.includes('/auth/refresh-token')", config.url.includes("/auth/refresh-token"));

            // Appending token to the header from localStorage
            let token = localStorage.getItem("SHIPLOCAL_USER_INFO")
                ? JSON.parse(localStorage.getItem("SHIPLOCAL_USER_INFO") || "{}").tokenInfo.accessToken
                : null;
            if (config && config.url && config.url.includes("/auth/refresh-access-token")) {
                let refreshToken = localStorage.getItem("SHIPLOCAL_USER_INFO")
                    ? JSON.parse(localStorage.getItem("SHIPLOCAL_USER_INFO") || "{}").tokenInfo.refreshToken
                    : {};
                config.headers["Authorization"] = "Bearer " + refreshToken;
            } else if (token) {
                // console.log("config.url", config.url);
                config.headers["Authorization"] = "Bearer " + token;
            }

            config.headers["Accept"] = "application/json, text/plain, */*";
            config.headers["Content-Type"] = "application/json;charset=utf-8";

            // console.log("config123", config);

            return config;
        }, undefined);

        // Using response interceptor to check for the refresh token mechanism
        axiosResponseInterceptor = axios.interceptors.response.use(
            (response) => response,
            function (err) {
                // console.log("responseerr", err);
                const originalRequest = err.config;
                if (err && err.response && err.response.status === 401 && !originalRequest._retry) {
                    // Pushing all requests to a queue when the token refresh api is being called.
                    if (isRefreshing) {
                        return new Promise(function (resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        })
                            .then((token) => {
                                originalRequest.headers["Authorization"] = "Bearer " + token;
                                console.log("originalRequest", originalRequest);
                                return axios(originalRequest);
                            })
                            .catch((err) => {
                                return Promise.reject(err);
                            });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    return new Promise(function (resolve, reject) {
                        axios
                            .post(process.env.REACT_APP_BASE_URL + "/auth/refresh-access-token")
                            .then((response) => {
                                // console.log("response", response);
                                // If request is good...
                                if (response && response.status === 200 && response.data.access_token) {
                                    // console.log("response", response);
                                    let user = JSON.parse(localStorage.getItem("SHIPLOCAL_USER_INFO") || "{}");
                                    localStorage.setItem(
                                        "SHIPLOCAL_USER_INFO",
                                        JSON.stringify({
                                            ...user,
                                            isLoggedIn: true,
                                            tokenInfo: {
                                                ...user.tokenInfo,
                                                accessToken: response.data.access_token
                                            }
                                        })
                                    );
                                    originalRequest.headers["Authorization"] = "Bearer " + response.data.access_token;

                                    // Processing the failed queue
                                    processQueue(null, response.data.access_token);
                                    resolve(axios(originalRequest));
                                } else {
                                    logoutAction();
                                }
                            })
                            .catch((err) => {
                                logoutAction();
                                processQueue(err, null);
                                reject(err);
                            })
                            .finally(() => {
                                isRefreshing = false;
                            });
                    });
                }
                return Promise.reject(err);
            }
        );
    }

    // Returning the api response.
    return new Promise((resolve, reject) => {
        axios({
            method,
            url: url.startsWith("https") ? url : process.env.REACT_APP_BASE_URL + url,
            data: payload ? payload : null
        })
            .then((response) => {
                // console.log("AxiosResponse", response);
                if (response.status == 200) {
                    if (returnRequest) {
                        resolve({ data: response.data, request: response.request });
                    } else {
                        resolve(response.data);
                    }
                } else {
                    // sendError(response.data.message, "Error"); TODO: implement this
                }
            })
            .catch((err) => {
                if (
                    err.response != null &&
                    err.response.data != null &&
                    err.response.data.detail != null &&
                    (typeof err.response.data.detail === "string" || err.response.data.detail instanceof String)
                ) {
                    // sendError(err.response.data.detail, "Error"); TODO: implement this
                } else {
                    // sendError("Unexpected Error Occurred", "Error"); TODO: implement this
                }

                reject(err);
            });
    });
};
