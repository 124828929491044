import { ACTION_TYPES } from "./Backdrop.constants";

export const showLoader = (dispatch) => {
    console.log("calling showLoader");
    dispatch(showBackDropAction());
};

export const closeLoader = (dispatch) => {
    dispatch(closeBackDropAction());
};

const showBackDropAction = () => {
    return {
        type: ACTION_TYPES.SHOW_BACKROP
    };
};

const closeBackDropAction = () => {
    return {
        type: ACTION_TYPES.CLOSE_BACKROP
    };
};
