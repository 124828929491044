import { showLoader, closeLoader } from "common/components/dialog/backdrop/Backdrop.action";
import { triggerApi } from "common/services/apiConfig.config";
import { getLocal, setLocal } from "common/services/commonService.service";
import { ACTION_TYPES } from "./auth.constatns";

export const loginUser = (dispatch, tokenId) => {
    showLoader(dispatch);
    dispatch(loginBegin());
    const user_info = getLocal("SHIPLOCAL_USER_INFO", true);
    let new_payload = {};
    if (user_info) {
        new_payload = {
            ...user_info,
            isLoggedIn: user_info.isLoggedIn,
            tokenInfo: {
                accessToken: tokenId,
                refreshToken: tokenId
            }
        };
    } else {
        new_payload = {
            isLoggedIn: false,
            tokenInfo: {
                accessToken: tokenId,
                refreshToken: tokenId
            }
        };
    }
    setLocal("SHIPLOCAL_USER_INFO", JSON.stringify(new_payload), true);
    triggerApi("GET", "/internal/check-super-user", null)
        .then((res) => {
            if (res && res.status === "success") {
                new_payload["isLoggedIn"] = true;
                setLocal("SHIPLOCAL_USER_INFO", JSON.stringify(new_payload), true);
                dispatch(loginSuccess(tokenId));
                window.location.replace("/");
            } else {
                new_payload["isLoggedIn"] = false;
                setLocal("SHIPLOCAL_USER_INFO", JSON.stringify(new_payload), true);
                dispatch(loginFailure("Failed to authenticate"));
            }
        })
        .catch((err) => {
            console.log("error is ", err);
            new_payload["isLoggedIn"] = false;
            setLocal("SHIPLOCAL_USER_INFO", JSON.stringify(new_payload), true);
            dispatch(loginFailure(err.message));
        })
        .finally(() => {
            closeLoader(dispatch);
        });
};

const loginBegin = () => {
    return {
        type: ACTION_TYPES.INITIATE_LOGIN_BEGIN
    };
};

const loginSuccess = (payload) => {
    return {
        type: ACTION_TYPES.INITIATE_LOGIN_SUCCESS,
        payload
    };
};

const loginFailure = (payload) => {
    return {
        type: ACTION_TYPES.INITIATE_LOGIN_FAILURE,
        payload
    };
};

export const logoutUser = (dispatch) => {
    console.log("clicked on logout user");
    setLocal("SHIPLOCAL_USER_INFO", null, false);
    dispatch(logoutSuccess());
    window.location.replace("/pages/login/super-user");
};

const logoutSuccess = () => {
    return {
        type: ACTION_TYPES.LOGOUT_USER
    };
};
