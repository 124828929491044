import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
    IconButton,
    Typography,
    Card,
    CardContent,
    Box,
    Button,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogActions,
    RadioGroup,
    Radio,
    FormControlLabel
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { VENDOR_MAPPING } from "common/constants/mapping.constants";
import { useDispatch } from "react-redux";
import { updateOrderVendor } from "views/pages/orders/orders.action";

function ConfirmationDialogRaw(props) {
    const { onClose, value: currentVendorId, open, onHandleOK, orderDetails, orderId, ...other } = props;
    const [newVendorId, setNewVendorId] = useState(currentVendorId);
    const radioGroupRef = useRef(null);

    const { status: orderStatus, vendor: vendorName } = orderDetails;

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose();
        onHandleOK(newVendorId);
    };

    const handleChange = (event) => {
        setNewVendorId(event.target.value);
    };

    return (
        <Dialog
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            onClose={handleCancel}
            {...other}
        >
            <DialogTitle>Select a new vendor</DialogTitle>
            <DialogContent dividers>
                <Card variant="outlined">
                    <CardContent>
                        <Typography>Order ID: {orderId}</Typography>
                        <Typography>Current Vendor: {vendorName}</Typography>
                        <Typography>Status: {orderStatus}</Typography>
                        <Typography>Shipper Name: HyperMart</Typography>
                    </CardContent>
                </Card>
                <RadioGroup ref={radioGroupRef} aria-label="ringtone" name="ringtone" value={newVendorId} onChange={handleChange}>
                    {Object.entries(VENDOR_MAPPING).map((item) => {
                        const vendor_id = item[0];
                        const vendor_name = item[1];
                        return (
                            <FormControlLabel
                                value={vendor_id}
                                key={`${vendor_name}-${vendor_id}`}
                                control={<Radio />}
                                label={vendor_name}
                                disabled={vendor_id == currentVendorId}
                            />
                        );
                    })}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleOk} disabled={newVendorId == currentVendorId || newVendorId <= 0}>
                    Change
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onHandleOK: PropTypes.func.isRequired,
    orderDetails: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired
};

export default function ChangeVendorDialog({ isOpen = false, onClose, orderId, orderDetails }) {
    const { vendorId } = orderDetails;
    console.log("orderDetails in change vendor is ", orderDetails);
    const dispatch = useDispatch();

    const onHandleOK = (newProviderId) => {
        console.log("Clicked on handleOk", newProviderId);
        const payload = {
            order_id: orderId,
            service_provider_id: parseInt(newProviderId)
        };
        updateOrderVendor(dispatch, payload);
        // TODO: update logic
    };

    return (
        <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
            <IconButton>
                <CancelIcon color="primary" />
            </IconButton>
            <ConfirmationDialogRaw
                id="vendor-change-menu"
                keepMounted
                open={isOpen}
                onClose={onClose}
                value={vendorId}
                onHandleOK={onHandleOK}
                orderDetails={orderDetails}
                orderId={orderId}
            />
        </Box>
    );
}

ChangeVendorDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    orderDetails: PropTypes.object.isRequired
};
