import { backdropReducer } from "common/components/dialog/backdrop/Backdrop.reducer";
import { notificationReducer } from "common/components/dialog/notify-dialog/NotifyDialog.reducer";
import { combineReducers } from "redux";
import authReducer from "views/pages/authentication/auth.reduer";
import ordersReducer from "views/pages/orders/orders.reducer";

// reducer import
import customizationReducer from "./customizationReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    orders: ordersReducer,
    notification: notificationReducer,
    backdrop: backdropReducer
});

export default reducer;
