import React, { useEffect } from "react";

import MainCard from "ui-component/cards/MainCard";
import OrderListTable from "common/components/orders/OrderTable.component";
// import { ordersListData } from "mock/order-list.mock";
import { getLocal } from "common/services/commonService.service";
import { useSelector, useDispatch } from "react-redux";
import { fetchOngoingOrdersList, fetchOngoingOrdersStatuses } from "../orders.action";
import { Button, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
const OrdersListPage = () => {
    const dispatch = useDispatch();
    const checkForLogin = () => {
        const shipLocalUserInfo = getLocal("SHIPLOCAL_USER_INFO", true);
        if (!shipLocalUserInfo) {
            window.location.replace("/pages/login/super-user");
        } else {
            if (!shipLocalUserInfo.isLoggedIn) {
                window.location.replace("/pages/login/super-user");
            }
        }
        return;
    };

    const checkOngoingOrderStatus = () => {
        fetchOngoingOrdersStatuses(dispatch);
    };

    const refershOrderList = () => {
        fetchOngoingOrdersList(dispatch);
    };

    useEffect(() => {
        checkForLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchOngoingOrdersList(dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ordersStore = useSelector((state) => state.orders);

    return (
        <MainCard
            title="Orders List"
            secondary={
                <>
                    <Button onClick={checkOngoingOrderStatus}>Refresh Orders Statuses</Button>
                    <IconButton onClick={refershOrderList}>
                        <RefreshIcon sx={{ color: "#5e35b1" }} />
                    </IconButton>
                </>
            }
        >
            {ordersStore.ordersList && <OrderListTable ordersList={ordersStore.ordersList} />}
        </MainCard>
    );
};

export default OrdersListPage;
