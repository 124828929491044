import * as React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import PropTypes from "prop-types";

const booleanFlags = {
    is_cod: "is_cod"
};

export default function OrdersListData({ orderDetails }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                <TableBody>
                    {Object.entries(orderDetails).map((item) => {
                        const keyName = item[0];
                        let value = item[1];
                        return (
                            <TableRow key={keyName} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover>
                                <TableCell component="th" scope="row">
                                    {keyName}
                                </TableCell>
                                <TableCell align="left">
                                    {booleanFlags.hasOwnProperty(keyName) ? (value === true ? "Yes" : "No") : value}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

OrdersListData.propTypes = {
    orderDetails: PropTypes.object
};
