import { ACTION_TYPES } from "./NotifyDialog.constant";

export const showNotificationDialog = (dispatch, payload) => {
    dispatch(openNotification(payload));
};

export const closeNotificationDialog = (dispatch) => {
    dispatch(closeNotification());
};

const openNotification = (payload) => {
    return {
        type: ACTION_TYPES.OPEN_NOTIFICATION_DIALOG,
        payload
    };
};

const closeNotification = () => {
    return {
        type: ACTION_TYPES.CLOSE_NOTIFICATION_DIALOG
    };
};
