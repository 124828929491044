export const initialState = {
    isLoading: false,
    loggedIn: false,
    token: undefined,
    err: undefined
};

export const ACTION_TYPES = {
    INITIATE_LOGIN_BEGIN: "INITIATE_LOGIN_BEGIN",
    INITIATE_LOGIN_SUCCESS: "INITIATE_LOGIN_SUCCESS",
    INITIATE_LOGIN_FAILURE: "INITIATE_LOGIN_FAILURE",
    LOGOUT_USER: "LOGOUT_USER"
};
