import { initialState, ACTION_TYPES } from "./orders.constant";

const ordersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ORDERS_LIST_BEGIN:
        case ACTION_TYPES.CHANGE_ORDER_VENDOR_BEGIN:
        case ACTION_TYPES.CANCEL_ORDER_BEGIN:
            return {
                ...state,
                loading: true
            };
        case ACTION_TYPES.FETCH_ORDERS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                ordersList: action.payload
            };
        case ACTION_TYPES.CHANGE_ORDER_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case ACTION_TYPES.CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case ACTION_TYPES.FETCH_ORDERS_LIST_FAILURE:
        case ACTION_TYPES.CHANGE_ORDER_VENDOR_FAILURE:
        case ACTION_TYPES.CANCEL_ORDER_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default ordersReducer;
