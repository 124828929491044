import React from "react";

import { Typography, Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import MainCard from "ui-component/cards/MainCard";

const CardSizeInfo = {
    xlCount: 2,
    lgCount: 3,
    mdCount: 3,
    smCount: 6,
    xsCount: 12
};

const KeyNames = {
    orderId: {
        key: "order_id",
        label: "Order ID"
    },
    fulfillment_provider_id: {
        key: "fulfillment_provider_id",
        label: "Fulfillment Provider ID"
    },
    provider_name: {
        key: "provider_name",
        label: "Provider Name"
    },
    fulfillment_provider__referenceid: {
        key: "fulfillment_provider__referenceid",
        label: "Provider Reference ID"
    },
    shipper_order_id: {
        key: "shipper_order_id",
        label: "Shipper Order ID"
    },
    invoice_amount: {
        key: "invoice_amount",
        label: "Invoice Amount"
    },
    is_cod: {
        key: "is_cod",
        label: "Is COD?"
    },
    address: {
        key: "address",
        label: "Address"
    }
};

const OrdersDetailPage = () => {
    const [searchParams] = useSearchParams();
    console.log("searchParams is ", searchParams);
    const orderId = searchParams.get("orderId");
    console.log("order id is ", orderId);
    return (
        <MainCard title={`Order Details - ${orderId}`}>
            <Grid container justifyContent="space-between">
                {Object.entries(KeyNames).map((item, idx) => {
                    const key = item[1].key;
                    const label = item[1].label;
                    return (
                        <Grid key={`${key}-${idx}`} item xs={CardSizeInfo.xsCount} sm={CardSizeInfo.smCount} md={CardSizeInfo.mdCount}>
                            <Grid container justifyContent="space-between" flexDirection="column">
                                <Grid item about="Key Name">
                                    <Typography>{label}</Typography>
                                </Grid>
                                <Grid item about="Key Value">
                                    <Typography>Label</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </MainCard>
    );
};

export default OrdersDetailPage;
