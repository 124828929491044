import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import OrderListData from "../orders/OrdersListData.component";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export default function OrderDetailsDialog({ orderDetails, onClose }) {
    const [open] = React.useState(Boolean(orderDetails));
    console.log("orderDetails is ", orderDetails);

    return (
        <div>
            <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                    Order Details (<strong>{orderDetails.order_id}</strong>)
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <OrderListData orderDetails={orderDetails} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

OrderDetailsDialog.propTypes = {
    orderDetails: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
