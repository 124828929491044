import { closeLoader, showLoader } from "common/components/dialog/backdrop/Backdrop.action";
import { showNotificationDialog } from "common/components/dialog/notify-dialog/NotifyDialog.action";
import { NOTIFICATION_TYPES } from "common/constants/common.enums";
import { triggerApi } from "common/services/apiConfig.config";
import { ACTION_TYPES } from "./orders.constant";

export const fetchOngoingOrdersList = (dispatch) => {
    showLoader(dispatch);
    dispatch(fetchOngoingOrdersBegin());
    triggerApi("GET", "/internal/fetch-console-orders", null)
        .then((res) => {
            if (res) {
                dispatch(fetchOngoingOrdersSuccess(res));
            } else {
                dispatch(fetchOngoingOrdersFailure("err"));
            }
        })
        .catch((err) => {
            dispatch(fetchOngoingOrdersFailure(err.message));
        })
        .finally(() => {
            closeLoader(dispatch);
        });
};

const fetchOngoingOrdersBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_ORDERS_LIST_BEGIN
    };
};

const fetchOngoingOrdersSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_ORDERS_LIST_SUCCESS,
        payload
    };
};

const fetchOngoingOrdersFailure = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_ORDERS_LIST_FAILURE,
        payload
    };
};

export const updateOrderVendor = (dispatch, payload) => {
    dispatch(updateOrderVendorBegin());
    showLoader(dispatch);
    let notificationDialogPayload = {
        type: NOTIFICATION_TYPES.SUCCESS,
        title: "Successful",
        showCancelButton: true,
        msg: "Vendor changed succesfully!"
    };
    triggerApi("POST", "/internal/change-vendor", payload)
        .then((res) => {
            console.log("change vendor status respose is ", res);
            if (res && res.status === "success") {
                dispatch(updateOrderVendorSuccess());
                notificationDialogPayload["msg"] = res.detail || "Vendor changed succesfully!";
                notificationDialogPayload["callback"] = () => fetchOngoingOrdersList(dispatch);
                showNotificationDialog(dispatch, notificationDialogPayload);
            } else {
                notificationDialogPayload["type"] = NOTIFICATION_TYPES.ERROR;
                notificationDialogPayload["title"] = "Error";
                notificationDialogPayload["msg"] = res.detail || "Failed to change the vendor!";
                dispatch(updateOrderVendorFailure("err"));
                showNotificationDialog(dispatch, notificationDialogPayload);
            }
        })
        .catch((err) => {
            notificationDialogPayload["type"] = NOTIFICATION_TYPES.ERROR;
            notificationDialogPayload["title"] = "Error";
            notificationDialogPayload["msg"] = err.message || "Failed to change the vendor!";
            dispatch(updateOrderVendorFailure(err.message));
            showNotificationDialog(dispatch, notificationDialogPayload);
        })
        .finally(() => {
            closeLoader(dispatch);
        });
};

const updateOrderVendorBegin = () => {
    return {
        type: ACTION_TYPES.CHANGE_ORDER_VENDOR_BEGIN
    };
};

const updateOrderVendorSuccess = () => {
    return {
        type: ACTION_TYPES.CHANGE_ORDER_VENDOR_SUCCESS
    };
};

const updateOrderVendorFailure = (payload) => {
    return {
        type: ACTION_TYPES.CHANGE_ORDER_VENDOR_FAILURE,
        payload
    };
};

export const cancelOrder = (dispatch, payload) => {
    dispatch(cancelOrderBegin());
    showLoader(dispatch);
    let notificationDialogPayload = {
        type: NOTIFICATION_TYPES.SUCCESS,
        title: "Successful",
        showCancelButton: true,
        msg: "Order cancelled sucessfully!"
    };
    triggerApi("POST", "/internal/cancel", payload)
        .then((res) => {
            console.log("change vendor status respose is ", res);
            if (res && res.status === "success") {
                dispatch(cancelOrderSuccess());
                notificationDialogPayload["msg"] = res.detail || "Order cancelled succesfully!";
                notificationDialogPayload["callback"] = () => fetchOngoingOrdersList(dispatch);
                showNotificationDialog(dispatch, notificationDialogPayload);
            } else {
                notificationDialogPayload["type"] = NOTIFICATION_TYPES.ERROR;
                notificationDialogPayload["title"] = "Error";
                notificationDialogPayload["msg"] = res.detail || "Failed to cancel the order!";
                dispatch(cancelOrderFailure("err"));
                showNotificationDialog(dispatch, notificationDialogPayload);
            }
        })
        .catch((err) => {
            notificationDialogPayload["type"] = NOTIFICATION_TYPES.ERROR;
            notificationDialogPayload["title"] = "Error";
            notificationDialogPayload["msg"] = err.message || "Failed to cancel the order!";
            dispatch(cancelOrderFailure(err.message));
            showNotificationDialog(dispatch, notificationDialogPayload);
        })
        .finally(() => {
            closeLoader(dispatch);
        });
};

const cancelOrderBegin = () => {
    return {
        type: ACTION_TYPES.CANCEL_ORDER_BEGIN
    };
};

const cancelOrderSuccess = () => {
    return {
        type: ACTION_TYPES.CANCEL_ORDER_SUCCESS
    };
};

const cancelOrderFailure = (payload) => {
    return {
        type: ACTION_TYPES.CANCEL_ORDER_FAILURE,
        payload
    };
};

export const fetchOngoingOrdersStatuses = (dispatch) => {
    showLoader(dispatch);
    dispatch(fetchOngoingOrderStatusessBegin());
    triggerApi("POST", "/internal/fetch-ongoing-order-statuses", {})
        .then((res) => {
            if (res) {
                dispatch(fetchOngoingOrdersStatusesSuccess(res));
                let notificationDialogPayload = {
                    title: "Success",
                    showCancelButton: false
                };

                notificationDialogPayload["msg"] = res.detail || "";
                showNotificationDialog(dispatch, notificationDialogPayload);
            } else {
                dispatch(fetchOngoingOrderStatusesFailure("err"));
            }
        })
        .catch((err) => {
            dispatch(fetchOngoingOrderStatusesFailure(err.message));
        })
        .finally(() => {
            closeLoader(dispatch);
        });
};

const fetchOngoingOrdersStatusesSuccess = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_ORDERS_STATUSES_SUCCESS,
        payload
    };
};

const fetchOngoingOrderStatusessBegin = () => {
    return {
        type: ACTION_TYPES.FETCH_ORDERS_STATUSES_BEGIN
    };
};

const fetchOngoingOrderStatusesFailure = (payload) => {
    return {
        type: ACTION_TYPES.FETCH_ORDERS_STATUSES_FAILURE,
        payload
    };
};
