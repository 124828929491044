import { initialState, ACTION_TYPES } from "./Backdrop.constants";

export const backdropReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTION_TYPES.SHOW_BACKROP:
            return {
                ...state,
                isVisible: true
            };
        case ACTION_TYPES.CLOSE_BACKROP:
            return {
                ...state,
                isVisible: false
            };
        default:
            return state;
    }
};
